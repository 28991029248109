<template>
    <div class="wishMatchClear">
        <headComp></headComp>
        <breadComp :wishMatchCombo="wishComboName" :clearFrom="from"></breadComp>

        <div class="centerWrapper">
            <div class="address">
                <div class="top">
                    <div class="text">收货地址</div>
                    <div class="ope">
                        <!-- <el-button type="text" icon="el-icon-plus" @click="$router.push('/personal/address')">新增收获地址</el-button> -->
                        <el-button type="text" icon="el-icon-plus" @click="editAddressId='';dialogVisible=true">新增收获地址</el-button>
                    </div>
                </div>
                <div class="list">
                    <!-- <div :class="{'item':1,'active':choseAddress==i}" v-for="i in 4" :key="i" @click="choseAddress=i">
                        <div class="line1">
                            <span class="name">刘小平</span>
                            <span class="default">默认</span>
                        </div>
                        <div class="dash"></div>
                        <div class="add_phone">
                            四川省成都市金堂县四川省成都市金堂县金堂城区观岭大道555号雍锦半岛11-1602  <span>13088005333</span>
                        </div>
                        <div class="opes">
                            <el-button type="text">修改</el-button>
                            <el-button type="text">删除</el-button>
                            <el-button type="text">设为默认</el-button>
                        </div>
                        <img src="../assets/imgs/effect10.png"/>
                    </div> -->
                    <div :class="{'item':1,'active':choseAddress.receiveAddressId==item.receiveAddressId}" v-for="(item,i) in receiveAddressList" :key="i" @click="choseAddress=item">
                        <div class="line1">
                            <span class="name">{{item.receiveUser}}</span>
                            <span class="default" v-show="item.isDefault=='Y'">默认</span>
                        </div>
                        <div class="dash"></div>
                        <div class="add_phone">
                            {{item.provinceName}}{{item.cityName}}{{item.countyName}}{{item.address}}  <span>{{item.telephone}}</span>
                        </div>
                        <div class="opes">
                            <el-button type="text" @click.stop="modifyAdd(item)">修改</el-button>
                            <el-button type="text" @click.stop="deleteAdd(item)">删除</el-button>
                            <el-button type="text" v-show="item.isDefault!='Y'" @click.stop="setDefaultAdd(item)">设为默认</el-button>
                        </div>
                        <img src="../assets/imgs/effect10.png"/>
                    </div>
                </div>
            </div>
            <div class="expectTime">
                <div class="top">
                    <div class="text">期望发货时间</div>
                </div>
                <div class="picker">
                     <el-date-picker
                        v-model="expectedArrivalDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期">
                    </el-date-picker>
                </div>
            </div>
            <div class="consignment">
                <div class="top">
                    <div class="text">送货清单</div>
                </div>
                <div class="tableContainer">
                    <div class="tableHead cell">
                        <div class="goods">商品</div>
                        <div class="price">单价</div>
                        <div class="num">数量</div>
                        <div class="total">金额</div>
                    </div>
                    <div class="tableBody">
                        <div class="deliveryWay">
                            <span>配送方式</span>
                            <div class="list">
                                <div :class="['item',deliveryType==1?'active':'']" @click="deliveryType=1">
                                    指定物流+上门送装
                                    <!-- <span>￥578</span> -->
                                    <img src="../assets/imgs/effect11.png"/>
                                </div>
                                <div :class="['item',deliveryType==2?'active':'']" @click="deliveryType=2">
                                    指定物流+自提
                                    <!-- <span>￥578</span> -->
                                    <img src="../assets/imgs/effect11.png"/>
                                </div>
                                <div :class="['item',deliveryType==3?'active':'']" @click="deliveryType=3">
                                    本店合作物流
                                    <img src="../assets/imgs/effect11.png"/>
                                </div>
                            </div>
                            <div class="lan">指定物流费用更省，售后服务更好，官方保障，收货请及时检查！</div>
                        </div>
                        <!-- <div class="storeLine">
                            <div class="store">
                                <img src="../assets/imgs/effect12.png"/>
                                <span class="name">纾康</span>
                                <span class="add">广东佛山</span>
                            </div>
                            <div class="goodsLine cell">
                                <div class="goods">
                                    <img src="../assets/imgs/place33.png"/>
                                    <div class="text">纾康 现代简约 科技布 （1+3+左贵妃）沙发组合</div>
                                </div>
                                <div class="price">￥5799.00</div>
                                <div class="num">1</div>
                                <div class="total">￥5799</div>
                            </div>
                        </div> -->
                        <div class="storeLine" v-for="(item,i) in chosenGoodsList" :key="i">
                            <div class="store">
                                <img src="../assets/imgs/effect12.png"/>
                                <span class="name">{{item.brandName}}</span>
                                <!-- <span class="add">广东佛山</span> -->
                            </div>
                            <div class="goodsLine cell">
                                <div class="goods">
                                    <!-- <img src="../assets/imgs/place33.png"/> -->
                                    <img :src="item.cover"/>
                                    <div class="text">{{item.name}}</div>
                                </div>
                                <div class="price">￥{{item.ourShopPrice}}</div>
                                <div class="num">{{item._goodsNumber}}</div>
                                <div class="total">￥{{item.calcTotalPrice}}</div>
                            </div>
                            <div class="msg">
                                <span>给卖家留言:</span>
                                <el-input v-model="item._remark" placeholder="" autocomplete="off"></el-input>
                            </div>
                        </div>
                        <!-- <div class="msg">
                            <span>给卖家留言:</span>
                            <el-input v-model="remark" placeholder="" autocomplete="off"></el-input>
                        </div> -->
                    </div>
                    <div class="sumUp">
                        <div class="line">
                            <div class="text">
                                <span>{{chosenGoodsList.length}}</span>件商品，商品总金额：
                            </div>
                            <span>￥{{goodsTotalCost}}</span>
                        </div>
                        <!-- <div class="line">
                            <div class="text">
                                运费：
                            </div>
                            <span>￥{{deliveryCost}}</span>
                        </div>
                        <div class="line">
                            <div class="text">
                                配送安装费：
                            </div>
                            <span>￥{{installCost}}</span>
                        </div> -->
                        <div class="totally">
                            <div class="totalPrice">
                                <div class="text">应付总额：</div>
                                <span>￥{{goodsTotalCost+deliveryCost+installCost}}</span>
                            </div>
                            <div class="add_phone">
                                <div class="add">
                                    <span>寄送至：</span>
                                    {{choseAddress.provinceName}}{{choseAddress.cityName}}{{choseAddress.countyName}}{{choseAddress.address}}
                                </div>
                                <div class="add">
                                    <span>收货人：</span>
                                    {{choseAddress.receiveUser}} {{choseAddress.telephone}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submitOrder">
                    <!-- <div class="notice">
                        <el-checkbox v-model="checked"></el-checkbox>
                         我已阅读并同意《<span>平台购物须知</span>》
                    </div> -->
                    <el-button type="danger" @click="subOrder">提交订单</el-button>
                </div>
            </div>
        </div>

        <el-dialog
        :title="editAddressId==''?'新增地址':'修改地址'"
        :visible.sync="dialogVisible"
        width="fit-content"
        :before-close="handleClose">
        <addressComp @close="closeAddressComp" :editAddressId="editAddressId"></addressComp>
        </el-dialog>
        
        <!-- <payComp class="cusStyle" :clearGoodsList="clearGoodsList" @close="closePayComp" :showPayComp="showPayComp" v-show="showPayComp"></payComp> -->
        <tipBox1 :order="orderObj" :dialogVisible="showPayComp" @close="showPayComp=false"></tipBox1>
        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import payComp from "@/components/payComp.vue"
import addressComp from "@/components/addressComp.vue"
import tipBox1 from "@/components/tipBox1.vue"
import {getReceiveAddressList,submitOrderBatch,submitOrder,deleteReceiveAddress,editDefaultReceiveAddress} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp,payComp,addressComp,tipBox1
    },
    data(){
        return{
            editAddressId:"",
            choseAddress:"",
            expectedArrivalDate:'',
            remark:"",
            checked:false,
            chosenGoodsList:[],
            receiveAddressList:[],//收货地址列表
            deliveryType:1,
            goodsTotalCost:0,//商品总费用
            deliveryCost:0,//运费
            installCost:0,//安装费
            wishComboName:"",
            from:"",//来自购物车或是随心配或是直接购买
            showPayComp:false,//显示付款卡片
            clearGoodsList:[],
            pageOpe:false,
            dialogVisible:false,
            orderObj:{},
        }
    },
    methods: {
        handleClose(done){
            done();
        },
        // 获取收货地址
        gainReceiveAddress(){
            getReceiveAddressList().then(res=>{
                // console.log("收货地址列表",res);
                if(res.code==200){
                    this.receiveAddressList=res.data;
                    res.data.forEach(item=>{
                        if(item.isDefault=='Y'&&!this.pageOpe){
                            this.choseAddress=item;
                        }
                    })
                }
            })
        },
        modifyAdd(item){
            // this.$router.push({
            //     path:'/personal/address',
            //     query:{addId:item.receiveAddressId}
            // })

            this.editAddressId=item.receiveAddressId;
            this.dialogVisible=true;
        },
        deleteAdd(item){
            deleteReceiveAddress({receiveAddressId:item.receiveAddressId}).then(res=>{
                // console.log("删除地址结果",res);
                if(res.code==200){
                    // this.$message.success(res.msg||res.message);
                    this.gainReceiveAddress();
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        setDefaultAdd(item){
            this.pageOpe=true;
            editDefaultReceiveAddress({receiveAddressId:item.receiveAddressId}).then(res=>{
                // console.log("设为默认地址结果",res);
                if(res.code==200){
                    // this.$message.success(res.msg||res.message);
                    this.gainReceiveAddress();
                }else{
                    this.$message(res.msg||res.message)
                }
            })
        },
        // 提交订单
        subOrder(){
            // if(!this.checked){
            //     this.$message("请阅读并同意《平台购物须知》")
            //     return
            // }
            if(!this.choseAddress.receiveAddressId){
                this.$message("请选择收货地址");
                return;
            }
            if(this.expectedArrivalDate==''){
                this.$message("请选择期望发货时间");
                return
            }

            if(this.from==1){
                var ids=this.chosenGoodsList.map(item=>{
                    return{
                        // shoppingcartId:item.shoppingCartId,
                        shoppingCartId:item.shoppingCartId,
                        remark:item._remark
                    }
                });
                // console.log(ids);

                submitOrder({
                    receiveAddressId:this.choseAddress.receiveAddressId,
                    expectedArrivalDate:this.expectedArrivalDate,
                    deliveryType:this.deliveryType,
                    remark:this.remark,
                    // shoppingCartIdList:ids,
                    shoppingCartList:ids,
                }).then(res=>{
                    // console.log("提交结果",res);
                    if(res.code==200){
                        this.$message.success(res.msg||res.message)
                        this.clearGoodsList=this.chosenGoodsList;
                        this.orderObj=res.data;
                        this.showPayComp=true;

                    }else{
                        this.$message(res.msg||res.message)
                    }
                })
            }else{
                var obj={
                    receiveAddressId:this.choseAddress.receiveAddressId,
                    expectedArrivalDate:this.expectedArrivalDate,
                    deliveryType:this.deliveryType,
                    remark:this.remark,
                    orderGoodsList:this.chosenGoodsList.map(item=>{return{goodsId:item.goodsId,count:item._goodsNumber,remark:item._remark}})
                }
                // console.log(obj)
                submitOrderBatch(obj).then(res=>{
                    // console.log("提交订单结果",res);
                    if(res.code==200){
                        this.$message.success(res.msg||res.message);
                        this.clearGoodsList=this.chosenGoodsList;
                        this.orderObj=res.data;
                        this.showPayComp=true;
                    }else{
                        this.$message(res.msg||res.message)
                    }
                })
            }
        },
        // 关闭支付弹框
        closePayComp(){
            this.showPayComp=false
        },
        closeAddressComp(){
            this.gainReceiveAddress();
            this.dialogVisible=false;
        },
    },
    created() {
        if(sessionStorage.wishChosenGoodsList){
            this.chosenGoodsList=JSON.parse(sessionStorage.wishChosenGoodsList)
            // console.log(this.chosenGoodsList)
        }else if(this.$route.query.from==1&&sessionStorage.carChosenGoodsList){
            this.from=1;
            this.chosenGoodsList=JSON.parse(sessionStorage.carChosenGoodsList);
            // console.log(this.chosenGoodsList)
        }else if(this.$route.query.from==2&&sessionStorage.immeChosenGoodsList){
            this.from=2;
            this.chosenGoodsList=JSON.parse(sessionStorage.immeChosenGoodsList);
            // console.log(this.chosenGoodsList)
        }
        var price=0;
        this.chosenGoodsList.forEach(item=>{
            this.$set(item,'_remark','')
            price+=parseFloat(item.calcTotalPrice)
        })
        this.goodsTotalCost=price;

        if(this.$route.params.comboName){
            sessionStorage.wishComboName=this.$route.params.comboName;
            this.wishComboName=this.$route.params.comboName
        }else if(sessionStorage.wishComboName){
            this.wishComboName=sessionStorage.wishComboName
        }

        this.gainReceiveAddress()
    },
}
</script>

<style lang="less" scoped>
.wishMatchClear{
    .address{
        margin-top: 50px;
        .top{
            display: flex;
            justify-content: space-between;
            .text{
                font-size: 20px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
            }
            .opes{
                .el-button{
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(77, 164, 224, 1);
                }
            }
        }
        .list{
            margin-top: 13px;
            display: flex;
            align-items: center;
            // justify-content: space-between;
            .item{
                width: 350px;
                height: 119px;
                box-sizing: border-box;
                padding: 8px 16px;
                background-image: url("../assets/imgs/effect8.png");
                background-size: 100% 100%;
                cursor: pointer;
                position: relative;
                margin-right: 28px;
                &>img{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    opacity: 0;
                    width: 28px;
                    height: 28px;
                }
                &.active{
                    background-image: url("../assets/imgs/effect9.png");
                    img{
                        opacity: 1;
                    }
                }
                .line1{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .name{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 1);
                    }
                    .default{
                        width: 39px;
                        height: 21px;
                        border: 1px solid rgba(241, 227, 215, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(182, 121, 69, 1);
                    }
                }
                .dash{
                    margin-top: 9px;
                    border-top: 1px dashed rgba(224, 224, 224, 1);
                }
                .add_phone{
                    margin-top: 9px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0, 0, 0, 1);
                    height: 35.2px;
                }
                .opes{
                    // margin-top: 3px;
                    text-align: right;
                    .el-button{
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(77, 164, 224, 1);
                        padding: 0;
                    }
                }
            }
        }
    }
    .expectTime{
        margin-top: 50px;
        .top{
            .text{
                font-size: 20px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
            }
        }
        .picker{
            margin-top: 16px;
        }
    }
    .consignment{
        margin-top: 50px;
        .top{
            .text{
                font-size: 20px;
                font-weight: 700;
                color: rgba(0, 0, 0, 1);
            }
        }
        .tableContainer{
            margin-top: 16px;
            .cell{
                padding: 0 16px;
                display: flex;
                align-items: center;
                .goods{
                    width: 60%;
                }
                .price{
                    width: 20%;
                }
                .num{
                    width: 10%;
                }
                .total{
                    width: 10%;
                }
            }
            .tableHead{
                height: 53px;
                background: rgba(245, 245, 245, 1);
                font-size: 15px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
            }
            .tableBody{
                margin-top: 17px;
                padding-bottom: 17px;
                border: 1px solid rgba(230, 230, 230, 1);
                box-sizing: border-box;
                .deliveryWay{
                    height: 81px;
                    background: rgba(254, 248, 243, 1);
                    padding: 0 15px;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    font-weight: 400;
                    color: rgba(102, 102, 102, 1);
                    .list{
                        margin: 0 33px;
                        display: flex;
                        align-items: center;
                        .item{
                            margin-right: 15px;
                            padding: 0 40px;
                            height: 34px;
                            background: rgba(255, 255, 255, 1);
                            border: 1px solid rgba(230, 230, 230, 1);
                            display: flex;
                            align-items: center;
                            position: relative;
                            cursor: pointer;
                            font-size: 15px;
                            font-weight: 400;
                            color: rgba(102, 102, 102, 1);
                            &>span{
                                color: rgba(212, 48, 48, 1);
                                margin-left: 7px;
                            }
                            img{
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                opacity: 0;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            &.active{
                                background: rgba(254, 248, 243, 1);
                                border: 2px solid rgba(212, 48, 48, 1);
                                img{
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .lan{
                        font-size: 15px;
                        font-weight: 400;
                        color: rgba(212, 48, 48, 1);
                    }
                }
                .storeLine{
                    // margin-top: 30px;
                    margin-top: 35px;
                    .store{
                        padding: 0 15px;
                        display: flex;
                        align-items: center;
                        &>img{
                            width: 18px;
                        }
                        .name{
                            margin: 0 5px;
                            font-size: 14px;
                            font-weight: 700;
                            color: rgba(102, 102, 102, 1);
                        }
                        .add{
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(153, 153, 153, 1);
                        }
                    }
                    .goodsLine{
                        margin-top: 27px;
                        .goods{
                            display: flex;
                            // align-items: baseline;
                            img{
                                width: 116px;
                                height: 87px;
                            }
                            .text{
                                margin-left: 16px;
                                font-size: 14px;
                                font-weight: 400;
                                color: rgba(69, 69, 69, 1);
                            }
                        }
                        .price{
                            font-size: 14px;
                            font-weight: 400;
                            color: rgba(69, 69, 69, 1);
                        }
                        .num{
                            font-size: 14px;
                            font-weight: 500;
                            color: rgba(69, 69, 69, 1);
                        }
                        .total{
                            font-size: 14px;
                            font-weight: 700;
                            color: rgba(69, 69, 69, 1);
                        }
                    }
                }
                .msg{
                    // margin-top: 30px;
                    margin-top: 20px;
                    padding: 0 17px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(153, 153, 153, 1);
                    .el-input{
                        width: 599px;
                        height: 37px;
                        margin-left: 5px;
                        /deep/.el-input__inner{
                            height: 37px;
                            line-height: 37px;
                        }
                    }
                }
            }
            .sumUp{
                margin-top: 10px;
                .line{
                    margin-top: 20px;
                    padding: 0 20px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 700;
                    color: rgba(120, 120, 120, 1);
                    .text{
                        &>span{
                            color: rgba(212, 48, 48, 1);
                        }
                    }
                    &>span{
                        width: 85px;
                        text-align: right;
                    }
                }
                .totally{
                    margin-top: 30px;
                    padding: 0 20px;
                    height: 90px;
                    background: rgba(245, 245, 245, 1);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    .totalPrice{
                        display: flex;
                        align-items: baseline;
                        justify-content: flex-end;
                        .text{
                            font-size: 14px;
                            font-weight: 700;
                            color: rgba(120, 120, 120, 1);
                        }
                        &>span{
                            min-width: 85px;
                            text-align: right;
                            font-size: 22px;
                            font-weight: 900;
                            color: rgba(212, 48, 48, 1);
                        }
                    }
                    .add_phone{
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 700;
                        color: rgba(120, 120, 120, 1);
                        &>div{
                            text-align: right;
                            margin-left: 47px;
                            &:first-child{
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
        .submitOrder{
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;
            position: relative;
            .notice{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50% -50%);
                font-size: 18px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                &>span{
                    color: rgba(30, 109, 199, 1);
                    cursor: pointer;
                }
            }
            .el-button{
                width: 220px;
                height: 58px;
                border-radius: 6px;
                background: rgba(212, 48, 48, 1);
            }
        }
    }
    .cusStyle{
        position: fixed;
        // top: 269px;
        // top: 247px;
        // top: 285px;
        // left: calc(50% + 735px);
        top:50px;
        left:50%;
        transform:translateX(-50%);
        z-index:2010;
        background: #fff;
        // border: 1px solid #ccc;
        border: none;
        margin-left: 30px;
    }
}
</style>